
import { AnteciparDocumento } from '@/core/models/financeiro';
import { PageBase } from '@/core/models/shared';
import { ReceitaService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class SimulacaoQuitacao extends PageBase { 
    service = new ReceitaService();
    item = new AnteciparDocumento();
    overlay: boolean = false;

    tipoAmortizacaoId:number = 0;

    mounted() {
        const filter:any = this.$route.query;
        this.tipoAmortizacaoId = filter.tipoAmortizacaoId;
        this.overlay = false;

        this.service.SimularQuitacao(filter.receitaId, filter.dataSimulada)
        .then(
            res => {
                this.item = res.data;
                }, 
            err=>{
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } 
                else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            }
        ).finally(() =>{
            this.overlay = false;
        }); 
    }
    
}
